.card {
    /* min-height: 700px; */
    min-height: 70vh;
    background-color: #E7ECEF;
    border-radius: 25px;
    box-shadow: 4px 4px 10px 0px #00000040;
    padding: 20px 20px 0px 20px;
    overflow: hidden;
    overflow-y: auto;
}

.card::-webkit-scrollbar {
    width: 7px;


}

.card::-webkit-scrollbar-thumb {
    width: 7px;
    background-color: #A20427;
    border-radius: 50px;
}

.card::-webkit-scrollbar-track {
    background-color: #E7ECEF;
    border-radius: 50px;
    margin: 40px 0;

}

.card h1 {
    font-size: 32px;
    line-height: 39px;

    font-weight: 500;
    color: #A20427;
    margin-bottom: 5px;
}

.card p {

    font-size: 14px;
    line-height: 17px;
    color: #272932;

}

.inputWithLabel {
    display: flex;
    flex-direction: column;
}

.inputWithLabel label {
    margin-bottom: 5px;
    color: #272932;
    font-size: 14px;
    line-height: 16px;

}

.inputWithLabel input {
    color: #272932;
    font-size: 15px;
    line-height: 17px;

    border: 1px solid #272932;
    border-radius: 5px;
    /* height: 50px; */
    padding: 6px 15px;
}

.mb30 {
    margin-bottom: 30px;
}

.btn {
    background-color: #A20427;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 7px 30px;
    color: #EFF1ED;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.btn1 {
    width: 100%;
}

.btn:disabled {
    background-color: rgba(162, 4, 39, 0.3);
    color: #E7ECEF;
    /* mix-blend-mode: difference; */
}

.selectBox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.selectBox .select {
    padding: 5px 20px;
    outline: none;
    border: 1px solid #A20427;
    background-color: transparent;
    color: #A20427;
    border-radius: 8px;


}

.selectBox span {
    margin-right: 20px;
    font-size: 14px;

}

.mt40 {
    margin-top: 40px;
}

.mt30 {
    margin-top: 30px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb20 {
    margin-bottom: 20px;
}

.pb40 {
    padding-bottom: 40px;
}

.mt10 {
    margin-top: 10px;
}

.ml30 {
    margin-left: 30px;
}

.addressExp {
    font-size: 14px;

    line-height: 17px;
    margin-bottom: 13px;
    color: rgba(39, 41, 50, 0.7);
}

.backIcon {

    margin-bottom: 3px;
}

/* Fence Box */
.box {
    background-color: #A20427;
    height: 200px;
    border-radius: 10px;
    border: 1px solid #A20427;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.box p {

    font-size: 30px;
    line-height: 36px;
    text-align: center;

    color: #EFF1ED;

}

.mb10 {
    margin-bottom: 10px;
}

.mb20 {
    margin-bottom: 20px;
}

h6 {

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #272932;
}

/* Estimate  */
.estimateAndAdditionOption h1 {
    margin-bottom: 10px;

}

.estimateAndAdditionOption h2 {

    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #272932;
}

.estimateAndAdditionOption .textGray {

    font-size: 14px;
    line-height: 17px;
    color: rgba(39, 41, 50, 0.7);
}

.estimateAndAdditionOption .estimateBox {
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;

}

.estimateAndAdditionOption .estimateBox p {

    font-size: 12px;
    line-height: 15px;
    color: #A20427;

}

.estimateAndAdditionOption .estimateBox p:last-child {
    font-size: 16px;
    line-height: 19px;

}

.totalEstimateBox {
    display: flex;
    padding-bottom: 20px;
    /* border-bottom: 1px solid #272932; */
    margin-bottom: 20px;
}

.totalEstimateBox p:last-child {

    font-size: 16px;
    line-height: 19px;
    color: #A20427;

}

.deleteBtn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #FFFFFF;
    background: #272932;
    outline: none;
    border: none;
}

.numberCircle {
    width: 30px;
    height: 30px;
    background: #272932;

    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #E7ECEF;
    border-radius: 30px;
    text-align: center;

}

.cardBody {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    height: 100%;
}


@media screen and (min-width:1440px) and (max-width:1600px) {

    .btn {
        padding: 12px 30px;
        font-size: 17px;
        line-height: 21px;

    }

}

@media screen and (min-width:768px) and (max-width:991px) {
    .btn {
        padding: 10px 30px;
        font-size: 16px;
        line-height: 20px;

    }

    .card h1 {
        font-size: 26px;
        line-height: 30px;
    }

    .card p {
        font-size: 15px;
        line-height: 20px;

    }

    .ml30 {
        margin-left: 20px;
    }

    .inputWithLabel label {
        font-size: 10px;
        line-height: 12px;
    }

    .inputWithLabel input {
        font-size: 11px;
        line-height: 13px;
        padding: 5px 7.5px;
    }

    .box {
        height: 140px;
    }

    .mt30 {
        margin-top: 18px;
    }

    .mb30 {
        margin-bottom: 18px;
    }

    .addressExp {
        margin-bottom: 8px;
        font-size: 12px !important;
    }

    .mb50 {
        margin-bottom: 30px;
    }

    .mb10 {
        margin-bottom: 8px;
    }

    .mt10 {
        margin-top: 8px;
    }

}

@media screen and (min-width:992px) and (max-width:1440px) {

    .card h1 {
        font-size: 26px;
        line-height: 32px;
    }

    .card p {
        font-size: 14px;
        line-height: 16px;

    }

    .inputWithLabel label {
        font-size: 14px;
        line-height: 16px;
    }

    .inputWithLabel input {
        font-size: 15px;
        line-height: 17px;
        padding: 12px 15px;
    }

    .mt30 {
        margin-top: 20px;
    }

    .mb30 {
        margin-bottom: 20px;
    }

    .addressExp {
        margin-bottom: 10px;
    }

    .mb50 {
        margin-bottom: 35px;
    }

    .box {
        height: 150px;
    }

    .box p {
        font-size: 22px;
        line-height: 26px;

    }

    .btn {
        padding: 5px 30px;
        font-size: 14px;
        line-height: 16px;

    }




}

.mt20 {
    margin-top: 20px;
}

p {
    margin: 0px;
}

/* Loader */
.loader{
    display:flex;
    justify-content: center;
    align-items: center;
    height: 150px;
}