.woodFencing {}

.headerSection {
    height: 642px;
    /* height: 342px; */
    width: 100%;
    background-image: url(../../assets/contact-us-bg.png);
    background-position: center bottom;
    background-size: cover;

}

.headerSection .overLay {
    background: hsla(0, 0%, 0%, 0.4);
    backdrop-filter: blur(2.5px);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerSection .container {

    max-width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* padding-top: 126px; */
}

.headerSection .tagline {
    color: #FFFFFF;
    
    font-style: italic;
    font-size: 18px;
    line-height: 20px;
    text-align: center;


}

.headerSection .btn {
    padding: 13px 30px;
    background: #A20427;
    
    font-size: 15px;
    line-height: 17px;
    color: #E7ECEF;
    border-radius: 5px;
    margin: 20px 0;
}

.headerSection .heading {
    
    font-weight: 800;
    font-size: 50px;
    line-height: 52px;
    color: #E7ECEF;
    text-align: center;


}

/* Last Cards */
.lastCards {
    min-height: 100vh;
    padding: 25px 0;
}

.lastCards .cardBody {
    min-height: 50vh;
}

.cardBody .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5%;
    height: 100%;
}

.lastCards .imgBox {
    border-width: 0px 0px 10px 10px;
    border-style: solid;
    border-color: #A20427;
    filter: drop-shadow(1px 1px 10px #272932);
}

.lastCards .imgBox img {
    width: 100%;
    height: 100%;
}

.lastCards h4 {
    
    font-size: 22px;
    line-height: 24px;
    color: #A20427;
    margin-bottom: 30px;

}

.lastCards p {
    
    font-size: 14px;
    line-height: 16px;
    color: #272932;
}

/* Custom Fabrication */
.customFabrication {
    width: 100%;
    background-image: url(../../assets/contact-us-bg.png);
    background-position: center bottom;
    background-size: cover;
    z-index: 0;
    height: 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 73px;
}

.customFabrication .overLay {
    background: hsla(0, 0%, 100%, 0.7);
    backdrop-filter: blur(2.5px);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;

}

.customFabrication h3 {
    
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    color: #272932;


}

.customFabrication p {
    
    font-size: 14px;
    line-height: 16px;
    color: #272932;
    text-align: center;

}

.customFabrication button,
.packageSection button {
    background: #A20427;
    
    font-size: 14px;
    line-height: 16px;
    color: #EFF1ED;
    padding: 16px 21px;
    max-width: 400px;

}

.customFabrication .container {
    z-index: 2;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width:767px) {

    .headerSection .tagline {
        font-size: 20px;
        line-height: 25px;


    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;

    }

    .headerSection .heading {
        font-size: 40px;
        line-height: 50px;
        margin-top: 40px;


    }

    .imageSection .imageCard {
        height: 250px;
        margin-bottom: 30px;
    }

    .customFabrication .container {
        max-width: 90%;
    }

    .customFabrication p {
        font-size: 18px;
        line-height: 23px;
    }

    .customFabrication h3 {
        font-size: 28px;
        line-height: 35px;
    }

    .lastCards .imgBox img {
        width: 100%;
        height: 250px;
    }




}

@media screen and (max-width:425px) {

    .lastCards .imgBox img {
        width: 100%;
        height: 200px;
    }

}

@media screen and (max-width:375px) {

    .lastCards .imgBox img {
        width: 100%;
        height: 150px;
    }

}

@media screen and (max-width:1024px) {
    .customFabrication .container {
        /* max-width: 70%; */
    }
}



@media screen and (min-width:768px) and (max-width:1440px) {

    .cardsSection .card p,
    .customFabrication p,
    .packageSection .left p {
        font-size: 15px;
        line-height: 23px;

    }

    .cardsSection .card h3,
    .customFabrication h3,
    .packageSection .left h2 {
        font-size: 28px;
        line-height: 35px;

    }

    .cardsSection .card img {
        width: 120px;
        height: 120px;
    }

    .customFabrication button,
    .packageSection button {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;
    }

    .lastCards .imgBox {
        height: 100%;
    }

    .lastCards h4 {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 30px;

    }

    .lastCards p {
        font-size: 18px;
        line-height: 20px;
    }

    .headerSection .tagline {
        font-size: 18px;
        line-height: 23px;
    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;
    }

    .headerSection h1 {
        font-size: 28px;
        line-height: 35px;
    }

    .headerSection h6 {
        font-size: 20px;
        line-height: 28px;
    }

}