.body {
    background-color: #272932;
}

.body h1 {
    text-align: center;
    color: #E7ECEF;

}

.btnsBox {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.btnsBox button {
    color: #E7ECEF;
    background-color: #A20427;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.btnsBox button:last-child {
    margin-left: 20px;

}