.card {
    width: 100%;
    height: 100%;
    margin-bottom: 15px;
    border-radius: 10px;
}
.selected{
    position: relative;
}
.selected .img{
    border: 2px solid #A20427;
    border-radius: inherit;


}
.crossIcon{
    position: absolute;
    right:-25px;
    top: 0px;
    width: 25px;
    height: 25px;
    z-index: 1;
    transform: translate(-50%,-50%);
}
.crossIcon .img{
    width: 100%;
    height: 100%;
}
.card .img {
    width: 100%;
    height: 100px;
}

.card p {
    font-family: 'inter-400';
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    color: #000000;
    margin-bottom: 4px;

}