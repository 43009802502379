.header {
    z-index: 1;

}

.socialRelated {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 40px;
    width: 100%;
}

.socialRelated ul {
    padding-left: 0px;
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0px;
}

.socialRelated ul li {
    margin-right: 30px;
}
.socialRelated ul li > svg{
    height:20px ;
    width: auto;
}

.socialRelated>div {
    display: flex;
    align-items: center;
}

.socialRelated>div p {
    font-family: 'inter-400';
    font-size: 16px;
    line-height: 19px;
    color: #272932;

}
.socialRelated>div svg{
    width: 33px;
    height: 24px;
}

.container{
    max-width: 90%;
    background-color: inherit;
}
.logo{
    width: 300px;
    height: auto;
}
.link{
    margin-bottom: 10px;
    text-decoration: none;
    color: #272932;
}
@media screen and (min-width:1024px) {
.container{
    max-width: 85%;
}    
}
@media screen and (max-width:767px) {
    .socialRelated ul li > svg{
        height:18px ;
        width: auto;
    }
    .socialRelated>div p {
        font-size: 14px;
        line-height: 17px;
    
    }
    .socialRelated>div svg{
        width: 30px;
        height: 22px;
    }
    .logo{
        width: 200px;
        height: auto;
    }
    
    
    
}

@media screen and (max-width:570px) {
    .socialRelated ul li {
        margin-right: 20px;
    }
    .socialRelated ul li > svg{
        height:17px ;
        width: auto;
    }
    .socialRelated>div p {
        font-size: 13px;
        line-height: 16px;
    
    }
    .socialRelated>div svg{
        width: 28px;
        height: 20px;
    }
    
    
}

@media screen and (max-width:400px) {
    .socialRelated ul li {
        margin-right: 15px;
    }
    .socialRelated ul li > svg{
        height:16px ;
        width: auto;
    }
    .socialRelated>div p {
        font-size: 12px;
        line-height: 15px;
    
    }
    .socialRelated>div svg{
        width: 25px;
        height: 18px;
    }
    .logo{
        width: 180px;
        height: auto;
    }
    
    
    
}


@media screen and (min-width:1200px) {
    .link{
        margin-left: 15px;
    }
    
}