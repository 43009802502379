.card {
    height: 55vh;
    background: #A20427;
    border: 1px solid #A20427;
    border-radius: 20px;
    padding: 30px;
    padding-bottom: 50px;
    position: relative;
    margin-top: 80px;
}

.profile {
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    top: -65px;
    overflow: hidden;

}

.profile img {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    object-position:top center;

}

.card h1 {
    padding-top: 55px;
    font-weight: 700;
    
    font-size: 24px;
    line-height: 26px;
    color: #E7ECEF;
    margin-bottom: 10px;
}

.card .time {
    
    font-size: 15px;
    line-height: 17px;
    color: #E7ECEF;


}

.card .desc {
    
    font-size: 16px;
    line-height: 18px;
    color: #E7ECEF;
    margin-top: 25px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    height: 10vh;
}

.ratingAndLikes {
    display: flex;
    justify-content: space-between;
    margin-top: 33px;
}
.quote{
    width: 47px;
    height: 35px;
}

@media screen and (max-width:767px) {
    .card {
        padding: 20px;
        padding-bottom: 30px;
    }


    .card h1 {
        font-size: 28px;
        line-height: 35px;
    }

    .card .time {
        font-size: 18px;
        line-height: 22px;
    }

    .card .desc {
        font-size: 22px;
        line-height: 27px;
        height: 85px;
        -webkit-line-clamp: 3;



    }

    .ratingAndLikes {
        margin-top: 25px;
    }
    .quote{
        width: 60px;
        height: 55px;
    }
    

}