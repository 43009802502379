.headerSection {
    height: 85vh;
    width: 100%;
    background-image: url(../../assets/contact-us-bg.png);
    background-position: center bottom;
    background-size: cover;
}

.headerSection .overLay {
    background: hsla(229, 12%, 17%, 0.7);
    width: 100%;
    height: 100%;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.headerSection .container {
    max-width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.headerSection .tagline {
    color: #FFFFFF;
    
    font-style: italic;
    font-size: 17px;
    line-height: 19px;
    text-align: center
}

.headerSection .btn {
    padding: 13px 30px;
    background: #A20427;
    
    font-size: 15px;
    line-height: 17px;
    color: #E7ECEF;
    border-radius: 5px;
    margin: 20px 0;
}

.headerSection .heading {
    
    font-size: 40px;
    line-height: 42px;
    color: #E7ECEF;
    text-align: center;
}

.cardsSection {
    margin: 50px 0px;
}

.cardsSection .container {
    max-width: 95%;
    height: 100%;


}


@media screen and (max-width:767px) {

    .headerSection .tagline {
        font-size: 20px;
        line-height: 25px;


    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;

    }

    .headerSection .heading {
        font-size: 40px;
        line-height: 50px;
        margin-top: 40px;


    }


}