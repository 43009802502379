.contactUs {
    min-height: 80vh;
}

.contactUs .form {
    background: #E7ECEF;
    border-left: none;
    padding: 5%;
    border-radius: 50px 0 50px 0;
}

.left {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.left ul {
    padding-left: 0px;
    list-style: none;
}

.left ul li {
    display: flex;
    margin-bottom: 30px;

}

.left ul li .icon {
    color: #272932;
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.left ul li p {
    width: 100%;
    word-break: break-all;
}

.contactUs h1 {
    
    text-align: center;
    font-size: 22.5px;
    line-height: 39px;
    color: #272932;
    margin-bottom: 20px;
    text-transform: uppercase;

}

.contactUs p {
    
    font-size: 15px;
    line-height: 27px;
    color: #272932;

}

.mt30 {
    margin-top: 30px;
}

.mt50 {
    margin-top: 50px;
}

.contactUs .btnBox {
    display: flex;
    justify-content: center;
}

.contactUs .btnBox button {
    padding: 2.5px 40px;
    background: #A20427;
    border-radius: 5px;
    
    font-size: 14px;
    line-height: 28px;
    color: #E7ECEF;

}

@media screen and (max-width:767px) {

    .contactUs .form {
        padding: 40px 15px;
        border-left: 1px solid #272932;
        border-bottom: none;

    }


    .contactUs h1 {
        font-size: 28px;
        line-height: 35px;

    }

    .contactUs p {
        font-family: 'inter-400';
        font-size: 20px;
        line-height: 25px;
        color: #272932;


    }

    .contactUs .btnBox button {
        padding: 10px 35px;

        font-size: 20px;
        line-height: 25px;

    }


}