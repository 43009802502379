.footer {
    background: #272932;
    min-height: 25vh;
}

.container {
    max-width: 95%;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 55px 0px;

}

.footer h1 {
    
    font-size: 25px;
    line-height: 27px;
    color: #E7ECEF;
    margin-bottom: 20px;

}

.footer ul {
    padding-left: 0px;
    list-style: none;

}

.footer ul li {
    /*  */
    font-size: 16x;
    line-height: 18px;
    color: #E7ECEF;
    margin-bottom: 10px;
    font-weight: 300 !important;

}

.footer p.textWhite {
    
    font-size: 24px;
    line-height: 29px;

    color: #E7ECEF;

}

.footer .logo {
    width: 100%;
    height: 53px;
    margin-bottom: 10px;
}

.footer .logo img {
    width: 100%;
    height: 100%;
}

.socialIcons {
    display: flex;
    margin-top: 30px;
}

.socialIcons li {
    margin: 0px;
    margin-right: 30px;
    cursor: pointer;

}

.socialIcons li:last-child {
    margin-right: 0px;
}

.rightBox {
    background: #D9D9D9;
    padding: 15px;
}

.rightBox .container {
    padding: 0px;
}

.rightBox p {
    
    font-size: 24px;
    line-height: 29px;
    color: #A20427;
    text-align: center;
    width: 100%;

}

.lowerFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 10vh;
}

@media screen and (max-width:767px) {
    .container {
        padding: 40px 0px;
    }

    .footer .row>*:nth-child(1), .footer .row>*:nth-child(2), .footer .row>*:nth-child(3),
    .footer .row>*:nth-child(4) {
        margin-bottom: 30px;
    }

    .footer h1 {
        font-size: 22px;
        line-height: 24px;

    }

    .footer ul li {
        font-size: 16px;
        line-height: 18px;

    }

    .footer p.textWhite {
        font-size: 18px;
        line-height: 23px;
    }

    
    .rightBox p {
        font-size: 18px;
        line-height: 23px;

    }




}
@media screen and (max-width:1440px) {

    .footer h1 {
        font-size: 25px;
        line-height: 27px;

    }

    .footer ul li {
        font-size: 16px;
        line-height: 18px;

    }

    .footer p.textWhite {
        font-size: 18px;
        line-height: 23px;
    }

    
    .rightBox p {
        font-size: 18px;
        line-height: 23px;

    }

}

@media screen and (max-width:991px) {
    .footer .row>*:nth-child(1), .footer .row>*:nth-child(2) {
        margin-bottom: 40px;
    }




}

@media screen and (min-width:1440px) {
    .footer ul li {
        margin-bottom: 20px;
    }

}