.inputWithLabel {
    display: flex;
    flex-direction: column;
}

.inputWithLabel label {
    margin-bottom: 5px;
    color: #272932;
    font-size: 15px;
    line-height: 17px;
    
}

.inputWithLabel input {
    color: #272932;
    font-size: 18px;
    line-height: 10px;
    
    border: 0px;
    border-radius: 5px;
    padding: 6px 5px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.inputWithLabel textarea {
    border-radius: 5px;
    min-height: 100px;
    border: 0px;
    border-radius: 5px;
    padding: 6px 5px;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
}

@media screen and (max-width:767px) {

    .inputWithLabel label {
        font-size: 16px;
        line-height: 20px;

    }

    .inputWithLabel input {
        font-size: 16px;
        line-height: 22px;
        padding: 12px 15px;
    }

}