.rec-pagination .rec-dot {
    background: #E7ECEF;
    border: 1px solid #272932;
    box-shadow: unset;
    width: 7.5px;
    height: 7.5px
}

.rec-pagination .rec-dot_active {
    background: #A20427;
    box-shadow: unset;
    width: 7.5px;
    height: 7.5px;
    border: 1px solid #A20427;

}
@media screen and (min-width:1440px) {
    .rec-dot_active, .rec-dot{
        width: 30px;
        height: 30px;
    }
    
}