.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background-color: #272932 !important;
    color: #E7ECEF !important;
    border: none;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #272932 !important;
    border: none;
    color: #E7ECEF !important;

}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #272932 !important;
}

.ant-steps-icon {
    /* display: none; */
}

.ant-steps-item-icon {
    width: 40px !important;
    height: 40px !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: rgba(39, 41, 50, 0.7) !important;
    font-family: 'inter-300';
    font-size: 32px;
    border: none;
    mix-blend-mode: difference;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    color: #E7ECEF !important;

}


.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: #E7ECEF !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #272932 !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    font-family: 'inter-300';
    font-size: 32px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-steps-vertical {
    flex-direction: row !important;
}

.no-data {
    min-height: 55vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data h3 {
    font-size: 20px;
}

@media screen and (max-width:768px) {

    .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
        font-size: 20px;
    }

    .ant-steps-item-wait .ant-steps-item-icon {
        font-size: 20px;
    }

    .ant-steps-item-icon {
        width: 30px !important;
        height: 30px !important;
    }

}