* {
    font-family: 'Trebuchet MS', sans-serif;
}

.headerSection {
    height: 642px;
    width: 100%;
    background-image: url(../../assets/contact-us-bg.png);
    background-position: center bottom;
    background-size: cover;

}

.headerSection .overLay {
    background: hsla(0, 0%, 100%, 0.4);
    width: 100%;
    height: 100%;
}

.headerSection .container {
    height: 100%;
    max-width: 95%;
    /* padding-top: 126px; */
    z-index: 1;
}


.headerSection h6 {
    color: #A20427;

}

.headerSection .tagline {
    margin-top: 20px;
    font-family: 'inter-500';
    font-size: 22px;
    line-height: 27px;
    color: #A20427;


}

.headerSection .btn {
    padding: 13px 30px;
    background: #A20427;
    
    font-size: 14px;
    line-height: 16px;
    color: #E7ECEF;
    outline: none;
    border: none;
    margin-top: 20px;

}

.headerSection button {
    display: flex;
    flex-direction: column;
}

.headerSection button span:first-child {
    font-size: 14px;
    line-height: 18px;

}

.headerSection h1 {

    
    font-size: 32px;
    line-height: 39px;

    color: #A20427;

}

.formSection {
    margin-top: 50px;
    margin-bottom: 100px;
}

/* Cards */
.cardsSection {
    margin-bottom: 50px;
}

.cardsSection .card {
    height: 60vh;
    width: 100%;
    background-color: #fafafa;
    border-radius: 4px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.4);
}

.cardsSection .card p {
    
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #272932;
    margin-top: 10px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

}

.cardsSection .card h3 {
    
    font-size: 22px;
    line-height: 25px;
    color: #272932;
    margin-top: 20px;
    text-align: center;

}


.cardsSection .card img {
    width: 120px;
    height: 120px;
    align-self: center;
    object-fit: cover;
    object-position: top center;

}

/* Fence Financing */
.fenceFinancing {
    width: 100%;
    background-image: url(../../assets/contact-us-bg.png);
    background-position: center bottom;
    background-size: cover;
    z-index: 0;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 100px;
}

.fenceFinancing .overLay {
    background: hsla(0, 0%, 100%, 0.7);
    backdrop-filter: blur(2.5px);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:15px 0px;

}

.fenceFinancing h3 {
    
    font-weight: 800;
    font-size: 48px;
    line-height: 58px;
    color: #A20427;

}

.fenceFinancing p {
    
    font-size: 14px;
    line-height: 16px;
    color: #272932;

}

.fenceFinancing button,
.packageSection button {
    background: #A20427;
    
    font-size: 14px;
    line-height: 16px;
    color: #EFF1ED;
    padding: 16px 21px;
    max-width: 400px;

}

.fenceFinancing .container {
    z-index: 2;
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.packageSection {
    margin-bottom: 100px;
}

.packageSection .container {
    height: 100%;
}

.row {
    height: 100%;
    padding: 2%;
}

.packageSection .left {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.packageSection .left p {
    
    font-size: 14px;
    line-height: 16px;
    color: #272932;

}

.packageSection .left h2 {
    
    font-size: 48px;
    line-height: 52px;
    color: #A20427;

}

.packageSection .right {
    background: #E7ECEF;
    padding: 54px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 50px 0 50px 0;

}

.packageSection .right h4 {
    
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: #272932;
    margin-bottom: 20px;

}

.packageSection .right ul li {
    margin-bottom: 10px;
    
    font-size: 14px;
    line-height: 16px;
    color: #272932;


}

.packageSection .right button {
    margin-top: 20px;
}

.newFenceInstallation {
    height: 30vh;
    background-color: #E7ECEF;

}

.newFenceInstallation .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 2.5%;
    height: 100%;
}

.newFenceInstallation p {
    
    font-size: 12px;
    line-height: 16px;
    color: #272932;
    text-align: center;
}

.newFenceInstallation h5 {
    
    font-size: 11px;
    line-height: 16px;
    color: #272932;
    text-align: center;
    margin: 5px 0px;

}

.lastCards {
    min-height: 100vh;
    padding: 25px 0;
}

.lastCards .cardBody{
    min-height: 40vh;
}

.cardBody .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2.5%;
    height: 100%;
}

.lastCards .imgBox {
    border-width: 0px 0px 10px 10px;
    border-style: solid;
    border-color: #A20427;
    filter: drop-shadow(1px 1px 10px #272932);
}

.lastCards .imgBox img {
    width: 100%;
    height: 35vh;
}

.lastCards h4 {
    
    font-size: 25px;
    line-height: 27px;
    color: #A20427;
    margin-bottom: 30px;

}

.lastCards p {
    
    font-size: 18px;
    line-height: 20px;
    color: #272932;
}

.mb32 {
    margin-bottom: 32px !important;
}

.container {
    max-width: 95%;
}


@media screen and (max-width:767px) {

    .cardsSection .card p,
    .fenceFinancing p,
    .packageSection .left p {
        font-size: 18px;
        line-height: 23px;

    }

    .cardsSection .card h3,
    .fenceFinancing h3,
    .packageSection .left h2 {
        font-size: 28px;
        line-height: 35px;

    }

    .cardsSection .card {
        margin-bottom: 20px;
        height: 300px;
    }

    .cardsSection .card p {
        font-size: 17px;
        text-align: left;
        line-height: 23px;
        -webkit-line-clamp: 2;
        /* height: 46px; */
    }

    .cardsSection .card h3 {
        font-size: 25px;
        line-height: 32px;

    }

    .cardsSection .card img {
        width: 70px;
        height: 70px;

    }

    .cardsSection .card img {
        width: 120px;
        height: 120px;
    }

    .fenceFinancing button,
    .packageSection button {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;

    }


    .packageSection, .fenceFinancing {
        margin-bottom: 50px;
    }



    .packageSection .right {
        padding: 30px 0px;
        margin-top: 30px;

    }

    .packageSection .right ul li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
    }

    .packageSection .right button {
        margin-top: 20px;
    }

    .newFenceInstallation p {
        font-size: 18px !important;
        line-height: 20px;
    }

    .newFenceInstallation h5 {
        font-size: 18px !important;
        line-height: 20px;
    }

    .lastCards .imgBox {
        height: 100%;
    }

    .lastCards h4 {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 30px;

    }

    .lastCards p {
        /* font-size: 32px; */
        line-height: 40px;
    }

    .headerSection .tagline {
        font-size: 18px;
        line-height: 23px;

    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;


    }

    .headerSection button span:first-child {
        font-size: 12px;
        line-height: 16px;

    }

    .headerSection h1 {

        font-size: 28px;
        line-height: 35px;

    }

    .headerSection h6 {

        font-size: 20px;
        line-height: 28px;
    }






}

@media screen and (max-width:768px) {
    .fenceFinancing .container {
        padding: 40px 0px;
        max-width: 90%;
    }

}

@media screen and (min-width:768px) and (max-width:1440px) {

    .cardsSection .card p,
    .fenceFinancing p,
    .packageSection .left p {
        font-size: 15px;
        line-height: 23px;

    }

    .cardsSection .card h3,
    .fenceFinancing h3,
    .packageSection .left h2 {
        font-size: 28px;
        line-height: 35px;

    }

    .cardsSection .card img {
        width: 120px;
        height: 120px;
    }

    .fenceFinancing button,
    .packageSection button {
        font-size: 16px;
        line-height: 24px;
        padding: 12px 20px;

    }


    .packageSection , .fenceFinancing{
        margin-bottom: 70px;
    }



    .packageSection .right {
        padding: 30px 0px;

    }

    .packageSection .right ul li {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 20px;
    }

    .packageSection .right button {
        margin-top: 20px;
    }

    .newFenceInstallation p {
        font-size: 20px;
        line-height: 20px;
    }

    .newFenceInstallation h5 {
        font-size: 30px;
        line-height: 26px;
    }


    .lastCards .imgBox {
        height: 100%;
    }

    .lastCards h4 {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 30px;

    }

    .lastCards p {
        font-size: 18px;
        line-height: 20px;
    }

    .headerSection .tagline {
        font-size: 18px;
        line-height: 23px;

    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;


    }

    .headerSection button span:first-child {
        font-size: 12px;
        line-height: 16px;

    }

    .headerSection h1 {

        font-size: 28px;
        line-height: 35px;

    }

    .headerSection h6 {

        font-size: 20px;
        line-height: 28px;
    }




}



button {
    outline: none;
    border: none;
}

/* nabeel's sections */

.row {
    height: 100%;
}

.topLeft {
    text-align: left !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.topLeft h1 {
    font-size: 45px;
}

.topLeft h6 {
    font-size: 17.5px;
}

.topLeft p {
    font-size: 17.5px;
    color: #A20427;
}

.topLeft button {
    width: 220px;
}