.addInfoPage {
    width: 100%;
    min-height: 750px;
    height: auto;
    position: relative;
    

}
.map{
    position: absolute;
    left: 0%;
    top: 0%;
    min-height: inherit;
    height: inherit;
    width: 100%;
    z-index: -1;
}

.bgImg{
    background-image: url(../../assets/bg-img.png);
    background-repeat: no-repeat;
    background-size: cover;

}

.container{
    max-width: 40%;
    position: absolute;
    top: 190px;
    left: 40px;

}
.row{
        padding-top: 110px;
}

@media screen and (max-width:768px){
    .container{
        max-width: 50%;
    }
}
@media screen and (min-width:800px){
    .container{
        max-width: 50%;
    }
}
@media screen and (min-width:900px){
    .container{
        max-width: 40%;
    }
}
@media screen and (min-width:992px){
    .container{
        max-width: 45%;
    }
}
@media screen and (min-width:1024px){
    .container{
        max-width: 46%;
    }
}
@media screen and (min-width:1100px){
    .container{
        max-width: 40%;
    }

}
@media screen and (min-width:1300px){
    .container{
        max-width: 35%;
    }
    .addInfoPage{
        min-height: 100vh;  
      }
}
@media screen and (min-width:1440px){
    .container{
        max-width: 32%;
    }
}
@media screen and (min-width:1600px){
    .container{
        max-width: 34%;
    }
}
@media screen and (min-width:1800px){
    .container{
        max-width: 30%;
    }
}
@media screen and (min-width:1920px){
    .container{
        max-width: 25%;
    }
}
@media screen and (min-width:2000px){
    .container{
        max-width: 23%;
    }
}
@media screen and (min-width:2300px){
    .container{
        max-width: 22%;
    }
}
@media screen and (min-width:2560px){
    .container{
        max-width: 20%;
    }
}