
/* 
.mapBox{
    width: 100%;
    min-height: 100vh;
    height: 100%;
} */
.row{

        /* padding-top: 110px; */
}
.map{
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;

}
.map::-webkit-scrollbar-track{
    z-index: 9999;

}

.contentBox{
    position: absolute;
    z-index: 1;
    left: 5%;
    top: 110px;
    /* width: max-content; */
    width: 45%;
}
@media screen and (min-width:1220px) {
    .contentBox{
        width: 35%;
    }
    
}
@media screen and (min-width:1600px) {
    .contentBox{
        width: 30%;
    }
    
}
@media screen and (min-width:1800px) {
    .contentBox{
        width: 27%;
    }
    
}
@media screen and (min-width:1920px) {
    .contentBox{
        width: 25%;
    }
    
}









.container{
    position: absolute;
    left: 40px;
    top: 180px;
    z-index: 1;
}

@media screen and (max-width:1024px) {
    .map{
        min-height: 800px;
    
    }
    
    
}

/*  */

@media screen and (max-width:768px){
    .container{
        max-width: 50%;
    }
}
@media screen and (min-width:800px){
    .container{
        max-width: 50%;
    }
}
@media screen and (min-width:900px){
    .container{
        max-width: 40%;
    }
}
@media screen and (min-width:992px){
    .container{
        max-width: 45%;
    }
}
@media screen and (min-width:1024px){
    .container{
        max-width: 46%;
    }
}
@media screen and (min-width:1100px){
    .container{
        max-width: 40%;
    }

}
@media screen and (min-width:1300px){
    .container{
        max-width: 35%;
    }
    .addInfoPage{
        min-height: 100vh;  
      }
}
@media screen and (min-width:1440px){
    .container{
        max-width: 32%;
    }
}
@media screen and (min-width:1600px){
    .container{
        max-width: 34%;
    }
}
@media screen and (min-width:1800px){
    .container{
        max-width: 30%;
    }
}
@media screen and (min-width:1920px){
    .container{
        max-width: 25%;
    }
}
@media screen and (min-width:2000px){
    .container{
        max-width: 23%;
    }
}
@media screen and (min-width:2300px){
    .container{
        max-width: 22%;
    }
}
@media screen and (min-width:2560px){
    .container{
        max-width: 20%;
    }
}