h1.black {
    font-size: 32px;
    line-height: 39px;
    /*  */
    color: #272932;

}

h1.red {
    font-size: 32px;
    line-height: 39px;
    /*  */
    color: #A20427;

}

h2.red {
    /*  */
    font-size: 24px;
    line-height: 29px;
    color: #A20427;

}

h2.black {
    /*  */
    font-size: 24px;
    line-height: 29px;
    color: #272932;

}

p.black {
    /*  */
    font-size: 18px;
    line-height: 22px;
    color: #000000;

}

p.red {
    /*  */
    font-size: 18px;
    line-height: 22px;
    color: #A20427;
}

p.gray {
    /*  */
    font-size: 14px;
    line-height: 17px;

    color: rgba(39, 41, 50, 0.7);

}

p.bigGray {

    font-size: 18px;
    line-height: 22px;
    /*  */
    color: rgba(39, 41, 50, 0.7);

}

.summaryBox {
    border: 1px solid #626262;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
}

.summaryBox .left {
    height: 100%;
    align-items: center;
    display: flex;
    width: 100%;

}

.summaryBox .left h2 {
    width: 100%;

}

.numberCircle {
    width: 20px;
    height: 20px;
    background: #272932;
    /*  */
    font-size: 13px;
    line-height: 17px;
    color: #E7ECEF;
    border-radius: 30px;
    text-align: center;
    margin-right: 15px;

}

.mt30 {
    margin-top: 30px !important;
}

.mb30 {
    margin-bottom: 30px;
}

.mt24 {
    margin-top: 24px;
}

.mt20 {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.mt10 {
    margin-top: 10px;
}

.mt5 {
    margin-top: 5px;
}

.container {
    width: 95% !important;
}

.totalEstimate {
    display: flex;
    align-items: center;

}
.totalEstimate p,.totalEstimate h2{
    margin-bottom: 0px;
}

/* Property Inforamtion */
.propInfoBox {
    padding: 30px 20px;
    border: 1px solid #626262;
}

.propInfoBox p {
    margin-bottom: 5px;
}

/* Sales Team Card */

.salesTeamBox {
    padding: 17.5px 20px;
    border: 1px solid #626262;

}

.salesTeamBox h2 {
    margin-bottom: 12px;
}

.salesTeamCard p {
    text-align: center;
}

.salesTeamCard img {
    height: 150px;
    width: 90%;
    border-radius: 5px;
}

.addressBox {
    border: 1px solid #626262;
    padding: 25px 20px;
}

.backToHomeBtn {
    padding: 2.5px 40px;
    background: #A20427;
    border-radius: 5px;
    /*  */
    font-size: 14px;
    line-height: 28px;
    color: #E7ECEF;
    margin-top: 30px;

}

.btnBox {
    display: flex;
    justify-content: flex-end;
}

.maroonText{
    color: #A20427;
}

.grayText{
    color: rgba(39, 41, 50, 0.7);
}

.lengthCentered{
    height: 100%;
    background-color: #272932;
}

@media screen and (min-width:1440px) {
    .container {
        width: 90% !important;
    }

}

@media screen and (max-width:1440px) {
    h1.black {
        font-size: 22.5px;
        line-height: 39px;

    }

    h1.red {
        font-size: 22.5px;
        line-height: 39px;

    }

    h2.red {
        font-size: 20px;
        line-height: 30px;

    }

    h2.black {
        font-size: 20px;
        line-height: 30px;
    }

    p.black {
        font-size: 15px;
        line-height: 20px;

    }

    p.red {
        font-size: 15px;
        line-height: 20px;
    }

    p.gray {
        font-size: 13px;
        line-height: 15px;

    }
    
    
    p.bigGray {
        font-size: 16px;
        line-height: 18px;
        
    }
    
    
}

@media screen and (max-width:1220px) {
    h1.black {
        font-size: 26px;
        line-height: 33px;

    }

    h1.red {
        font-size: 26px;
        line-height: 33px;

    }

    h2.red {
        font-size: 20px;
        line-height: 25px;

    }

    h2.black {
        font-size: 20px;
        line-height: 25px;
    }

    p.black {
        font-size: 15px;
        line-height: 19px;

    }

    p.red {
        font-size: 15px;
        line-height: 19px;

    }

    p.gray {
        font-size: 13px;
        line-height: 16px;

    }

    p.bigGray {
        font-size: 17px;
        line-height: 21px;

    }

}

@media screen and (max-width:767px) {
    .btnBox {
        margin-top: 20px;
    }

    .salesTeamBox {
        margin-bottom: 30px;
    }

    .salesTeamCard img {
        height: 200px;
    }
}