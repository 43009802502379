.heroSection .bgImg {
    width: 100%;
    min-height: 75vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/about-us-bg.png);

}

.heroSection .bgImg .overLay {
    background: hsla(229, 12%, 17%, 0.7);
    width: 100%;
    min-height:inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.heroSection .container, .heroSection .row {
    height: 100%;

}

.heroSection .overLay .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 20px 0px;

}

.heroSection h3 {
    
    font-size: 40px;
    line-height: 42px;
    margin-bottom: 10px;
    color: #E7ECEF;

}

.heroSection p {
    
    font-size: 15px;
    line-height: 17px;
    color: #E7ECEF;

}

.container {
    max-width: 90%;
}

.joinOurTeam {
    margin-bottom: 63px;
    margin-top: 100px;
    min-height: 520px;
}

.joinOurTeam .rightBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.joinOurTeam h3 {
    
    font-size: 40px;
    line-height: 48px;

    color: #A20427;

}

.joinOurTeam button {
    padding: 10px 30px;
}

.joinOurTeam .imgBox {
    width: 100%;
    height: 100%;
}

.joinOurTeam .imgBox img {
    width: 100%;
    height: 100%;
}

.aboutUs button.btn {
    outline: none;
    border: none;
    background: #A20427;
    
    font-size: 14px;
    color: #E7ECEF;
    border-radius: 5px;

}

.mt30 {
    margin-top: 30px;
}

/* card */
.card {
    height: 25vh;
    margin: 2vh 0;
}

.card .heading {
    display: flex;
    align-items: center;
}

.card .heading h4 {
    
    font-weight: 700;
    font-size: 26px;
    line-height: 28px;
    color: #A20427;
    margin: 0px 20px;

}

.card p {
    
    font-size: 18px;
    line-height: 20px;
    color: #272932;

}

.whyChooseUS {
    margin-top: 70px;
}

.whyChooseUS h3 {
    
    font-size: 36px;
    line-height: 44px;
    color: #272932;
    text-align: center;
    margin-bottom: 40px;

}

@media screen and (max-width:767px) {
    .card {
        margin-bottom: 30px;
    }

    .headerSection .tagline {
        font-size: 20px;
        line-height: 25px;
    }

    .headerSection .btn {
        padding: 10px 30px;
        font-size: 18px;
        line-height: 22px;
    }

    .headerSection .heading {
        font-size: 40px;
        line-height: 50px;
        margin-top: 40px;
    }

    .joinOurTeam h3 {
        font-size: 40px;
        line-height: 50px;
        margin-top: 30px;

    }

    .joinOurTeam {
        margin-bottom: 50px;
        margin-top: 50px;
    }

    .joinOurTeam .imgBox {
        height: 300px;

    }

    .aboutUs button.btn {
        font-size: 18px;
        line-height: 26px;
    
    }

}

@media screen and (max-width:425px) {
    .joinOurTeam .imgBox {
        height: 220px;

    }
    .card .heading h4 {
        font-size: 28px;
        line-height: 36px;
     
    }
    .joinOurTeam h3 {
        font-size: 36px;
        line-height: 46px;    
    }
    


}